<template>
  <div class="app">
    <app-header :isAuth=true :isDark=false />
    <app-main />
    <app-footer />
  </div>
</template>

<script>

import { defineComponent, ref } from "vue";
import AppHeader from "components/AppHeader";
import AppFooter from "components/AppFooter";
import AppMain from "components/AppMain";

export default defineComponent({
  name: "MainLayout",
  components: {
    AppHeader,
    AppFooter,
    AppMain,
  },
});
</script>
